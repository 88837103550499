import Swiper from "swiper/swiper-bundle"

/**
 * Change event listener for the category select on the blog page
 */

const resourcesSelect = document.querySelector("#resources-select")

resourcesSelect &&
  resourcesSelect.addEventListener("change", (e) => {
    e.preventDefault()
    let categorySlug = e.target.value

    const url = document.location.origin

    if (e.target.value === "all") {
      window.location.href = `${url}/career-resources/`
    } else {
      window.location.href = `${url}/career-resources/?des=${categorySlug}`
    }
  })

const articlesSelect = document.querySelector("#articles-select")

articlesSelect &&
  articlesSelect.addEventListener("change", (e) => {
    e.preventDefault()
    let categorySlug = e.target.value

    const url = document.location.origin

    if (e.target.value === "all") {
      window.location.href = `${url}/career-articles/`
    } else {
      window.location.href = `${url}/career-articles/?des=${categorySlug}`
    }
  })

const coursesSelect = document.querySelector("#courses-select")

coursesSelect &&
  coursesSelect.addEventListener("change", (e) => {
    e.preventDefault()
    let categorySlug = e.target.value

    const url = document.location.origin

    if (e.target.value === "all") {
      window.location.href = `${url}/courses-and-education/`
    } else {
      window.location.href = `${url}/courses-and-education/?des=${categorySlug}`
    }
  })

const mobileOverflow = document.querySelectorAll(".mobile-overflow")

mobileOverflow.forEach((el) => {
  const childCount = el.children.length

  el.style.gridTemplateColumns = `repeat(${childCount}, 308px)`
  el.style.width = `${childCount * 308}px`
  el.style.overflow = "scroll"
})

const initResourcesSwiper = (swiperName, sildesPerScreen) => {
  const swiper = document.getElementById(swiperName)

  if (swiper) {
    // gets slides length to center and loop if there is more than 2 slides
    const slides = swiper.querySelectorAll(".swiper-slide").length

    new Swiper(swiper, {
      slidesPerView: "auto",
      spaceBetween: 16,

      loop: false,

      breakpoints: {
        768: {
          enabled: false,
        },
      },
    })
  }
}

initResourcesSwiper("courses-resources-slider")
initResourcesSwiper("stories-resources-slider")
