import Swiper from "swiper/swiper-bundle"

/**
 * Inits the perticular swiper and sets the number of slides per
 * screen for desktop
 *
 * @param {string} swiperName
 * @param {int} sildesPerScreen
 */

const initSwiper = (swiperName, sildesPerScreen) => {
  const swiper = document.getElementById(swiperName)

  if (swiper) {
    // gets slides length to center and loop if there is more than 2 slides
    const slides = swiper.querySelectorAll(".swiper-slide").length

    new Swiper(swiper, {
      slidesPerView: "auto",
      spaceBetween: 16,
      centerInsufficientSlides: true,
      centeredSlides: slides > 2,
      loop: slides > 2,
      loopAdditionalSlides: 3,
      navigation: {
        nextEl: `.${swiperName}__button-next`,
        prevEl: `.${swiperName}__button-prev`,
      },

      breakpoints: {
        768: {
          slidesPerView: sildesPerScreen,
          spaceBetween: 24,
          centerInsufficientSlides: false,
          centeredSlides: false,
          loop: false,
        },
      },
    })
  }
}

initSwiper("furthur-reading-slider", 2)
initSwiper("courses-slider", 2)
initSwiper("stories-slider", 1)
initSwiper("single-stories-slider", 1)

const initResourcesSwiper = (swiperName, sildesPerScreen) => {
  const swiper = document.getElementById(swiperName)

  if (swiper) {
    // gets slides length to center and loop if there is more than 2 slides

    new Swiper(swiper, {
      slidesPerView: "auto",
      spaceBetween: 16,

      loop: false,

      breakpoints: {
        768: {
          enabled: false,
        },
      },
    })
  }
}

initResourcesSwiper("courses-resources-slider")
initResourcesSwiper("stories-resources-slider")
