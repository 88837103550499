const menuItemHasChildren = document.querySelectorAll(".menu-item-has-children")
const submenus = document.querySelectorAll(".sub-menu")

menuItemHasChildren.forEach((el) => {
  el.addEventListener("click", (e) => {
    if (window.matchMedia("(max-width: 1000px)").matches) {
      const subMenu = el.querySelector(".sub-menu")
      const svg = el.querySelector("svg")
      if (!subMenu.contains(e.target)) {
        e.preventDefault()
        if (!subMenu.classList.contains("open")) {
          subMenu.classList.add("open")
          svg.classList.add("rotate")
        } else {
          subMenu.classList.remove("open")
          svg.classList.remove("rotate")
        }
      }
    }
  })
})

menuItemHasChildren.forEach((el) => {
  el.addEventListener("mouseover", (e) => {
    if (window.matchMedia("(min-width: 1000px)").matches) {
      const subMenu = el.querySelector(".sub-menu")
      const svg = el.querySelector("svg")

      subMenu.classList.add("open")

      svg.classList.add("rotate")
    }
  })
})

menuItemHasChildren.forEach((el) => {
  el.addEventListener("mouseleave", (e) => {
    if (window.matchMedia("(min-width: 1000px)").matches) {
      const subMenu = el.querySelector(".sub-menu")
      const svg = el.querySelector("svg")
      subMenu.classList.remove("open")
      svg.classList.remove("rotate")
    }
  })
})

submenus.forEach((el) => {
  el.addEventListener("focusout", (e) => {
    if (window.matchMedia("(min-width: 1000px)").matches) {
      const svg = el.querySelector("svg")
      if (!el.contains(e.relatedTarget)) {
        el.classList.remove("open")
        svg.classList.remove("rotate")
      }
    }
  })

  el.addEventListener("keyup", (e) => {
    if (window.matchMedia("(min-width: 1000px)").matches) {
      const svg = el.querySelector("svg")
      if (e.key === "Escape") {
        el.classList.remove("open")
        svg.classList.remove("rotate")
      }
    }
  })
})

// export const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
//   if (relatedTarget === null) return false

//   var node = relatedTarget.parentNode

//   while (node !== null) {
//     if (node === currentTarget) return true
//     node = node.parentNode
//   }

//   return false
// }
